@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Russo+One&display=swap');

.russo-one{
    font-family: "Russo One", sans-serif;
} 
.roboto {
    font-family: "Roboto", sans-serif;
} 

::-webkit-scrollbar {
    width: 5px;
  }
::-webkit-scrollbar-track {
    background: #03192F;
}
::-webkit-scrollbar-thumb {
    background: #00FFF0;
    border-radius: 20px;
    
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.main2 {
    background: radial-gradient(72.75% 72.75% at 50% 27.25%, #2BA5BE 0%, #1C8DA5 12.6%, #105971 30.55%, #093D52 44.51%, #000F1D 79.07%, #000C17 100%);
    opacity: 1;
    animation: glow 5s infinite alternate;
    z-index: 0;
}
@keyframes glow {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
.moving-block {
    animation: moveLeftRight 5s ease-in-out infinite;
}
@keyframes moveLeftRight {
    0% {
        transform: translateX(0);
    }
    50%{
        transform: translateX(20px);
    }
    100% {
        transform: translateX(0);
    }
}
.moving-block1 {
    animation: moveLeftRight 3s ease-in-out infinite;
}
@keyframes moveLeftRight {
    0% {
        transform: translateX(0);
    }
    50%{
        transform: translateX(15px);
    }
    100% {
        transform: translateX(0);
    }
}
.moving-block2 {
    animation: moveLeftRight 10s ease-in-out infinite;
}
@keyframes moveLeftRight {
    0% {
        transform: translateX(0);
    }
    50%{
        transform: translateX(30px);
    }
    100% {
        transform: translateX(0);
    }
}
.moving-block3 {
    animation: moveLeftRight 7s ease-in-out infinite;
}
@keyframes moveLeftRight {
    0% {
        transform: translateX(0);
    }
    50%{
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
    }
}
.moving-iceberg{
    animation: moveIceberg 5s ease-in-out infinite;
}
@keyframes moveIceberg {
    0% {
        transform: translateY(0);
    }
    50%{
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0);
    }
}
.button{
    display: inline-flex;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 74px;
    border: 0.5px solid #918F90;
    background: linear-gradient(180deg, #1D1B1C 0%, #000 81.19%, #252120 96.35%);
    box-shadow: 0px 0px 0.25px 1.25px #262524 inset, 3px 5px 2px -4.75px #FFF inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 0px 4.75px 0.25px -2.5px #FBFBFB inset, 1px 1px 3px 3px #1A1818 inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset;
}

.block1{
    border-radius: 20px;
    background: #03192F;
    box-shadow: 0px 4px 4px 0px rgba(192, 183, 232, 0.01);
    flex-grow: 1;
    flex-basis: 369px;
}
.block2{
    border-radius: 20px;
    background: #03192F;
    box-shadow: 0px 4px 4px 0px rgba(192, 183, 232, 0.01);
    flex-grow: 1;
    flex-basis: 497px;
}

@media (min-width: 1281px) {
    .left-blur {
        position: absolute;
        left: -1px;
        top: 0;
        width: 50px;
        height: 100%;
        background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, #000C17 100%);
        z-index: 99;
    }
    
    .right-blur {
        position: absolute;
        right: -1px;
        top: 0;
        width: 50px;
        height: 100%;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000C17 100%);
        z-index: 99;
    }
}
.swiper-main{
    border-radius: 20px;
    border: 1px solid #000C17;
    background: radial-gradient(7960.83% 100% at 50% 0%, #03192F 0%, #000C17 100%);
    box-shadow: 0px 4px 4px 0px rgba(192, 183, 232, 0.01);
}
.cash-block{
    background: linear-gradient(180deg, #000C17 0%, rgba(0, 12, 23, 0.00) 100%), linear-gradient(180deg, #000C17 0%, rgba(0, 12, 23, 0.00) 100%);
}
.blockhoveron{
    display: none;
}
.onhover:hover{
    .blockhover{
        display: none;
    }
    .blockhoveron{
        display: block;
        z-index: 10;
    }
    .imghover{
        filter: blur(7px);
        z-index: 0;
    }
}
@media (max-width: 1280px) {
    .onhover{
        .blockhover{
            display: none;
        }
        .blockhoveron{
            display: block;
            z-index: 10;
        }
        .imghover{
            filter: blur(7px);
            z-index: 0;
        }
    }
}
.blockcomp{ 
    background: linear-gradient(180deg, #000C17 0%, rgba(0, 12, 23, 0.00) 100%), linear-gradient(180deg, #000C17 0%, rgba(0, 12, 23, 0.00) 100%);
}

.Dropdown-control {
    cursor: pointer;
    font-style: normal!important;
    font-size: 16px!important;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    background-color: transparent!important;
    border: none!important;
    color: #fff!important;
    opacity: 0.7;
    text-transform: uppercase!important;
    padding-right:25px!important;
    
  }
  .Dropdown-menu {
    font-style: normal;
    font-size: 16px!important;
    max-height: 300px!important;
    
  }
  .Dropdown-arrow {
    border-color: #fff transparent transparent!important;
    margin-top: 3px;
   
  }
  .is-open .Dropdown-arrow {
    border-color: transparent transparent #fff!important;
  }